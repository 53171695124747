import React, { useEffect, useState } from "react";
import { widgetize, WidgetPropsMapping, WithCss } from "@sg-widgets/react-core";
import { Analytics, AnalyticCallback, List, NoDataFiller, ViewSelector} from "@sgss-ttd-widgets/components";

import * as listStyles from "@sgss-ttd-widgets/components/styles/list.less";
import * as widgetStyles from "./ttd-notifications.less";
import { NotificationModel } from "../models/notification.model";
import ActionTemplate from "./components/action-template";
import i18n from "./i18n";
import { useSgWidgetTranslation } from "../common/use-sg-widgets-translation";
import TitleTemplate from "./components/title-template";
import ItemTextTemplate from "./components/item-text-template";
import { getMergedStyles } from "../utils";

interface Props {
  items: NotificationModel[];
  hasUnauthorizedAccess: boolean;
  hasDataFetchingError: boolean;
  isLoading: boolean;
  pages: number;
  pageChange: (page: number) => void;
  itemClick: (data: { item: NotificationModel }) => void;
  seeAllClick: () => void;
  helpClick: () => void;
  analyticCallback: AnalyticCallback;
  retryClick: () => void;
}

const Notifications: React.FC<Props> = ({
  items,
  hasUnauthorizedAccess,
  hasDataFetchingError,
  isLoading,
  pages,
  pageChange,
  itemClick,
  seeAllClick,
  helpClick,
  analyticCallback,
  retryClick
}) => {
  const itemIconPropertyPath = 'serviceIcon';
  const itemIconClassesPropertyPath = 'serviceIconClasses';
  const itemIconTooltipPropertyPath = 'serviceFullName';
  const [itemsState, setItemsState] = useState(items);
  const [hasUnauthorizedAccessState, setHasUnauthorizedAccessState] = useState(hasUnauthorizedAccess);
  const [hasDataFetchingErrorState, setHasDataFetchingErrorState] = useState(hasDataFetchingError);
  const { currentLanguage, t: translate } = useSgWidgetTranslation(i18n);

  const createPayloadSeeAllClick = Analytics.payloadFactory('buttonClick', 'Click to see all notifications');
  const createPayloadItemClick = Analytics.payloadFactory('buttonClick', 'Click on a notification');
  const createPayloadHelpClick = Analytics.payloadFactory('buttonClick', 'Click on help');

  useEffect(() => {
    if (items !== itemsState) {
      setItemsState(items);
    }
  }, [items]);

  useEffect(() => {
    if (hasUnauthorizedAccess !== hasUnauthorizedAccessState) {
      setHasUnauthorizedAccessState(hasUnauthorizedAccess);
    }
  }, [hasUnauthorizedAccess]);

  useEffect(() => {
    if (hasDataFetchingError !== hasDataFetchingErrorState) {
      setHasDataFetchingErrorState(hasDataFetchingError);
    }
  }, [hasDataFetchingError]);

  const RefreshActionTemplate = (): JSX.Element => (
    <button
      className="btn btn-discreet-primary btn-lg btn-icon-start"
      onClick={() => {
        // const tasks = getNotificationsMock();
        // setItemsState(tasks);
        retryClick();
      }}>
      <i className="icon">refresh</i> {translate('ttdNotifications.retryBtn')}
    </button>
  );

  const actionsTemplate = <RefreshActionTemplate />;
  const content = 
    <ViewSelector
      itemsCount={1}
      hasUnauthorizedAccess={hasUnauthorizedAccessState}
      hasDataFetchingError={false}
      actionsTemplate={<RefreshActionTemplate />}
      language={currentLanguage}
      isLoading={isLoading}
      unauthorizedAccessFiller={
        <NoDataFiller title={translate('ttdNotifications.viewSelector.unauthorizedAccess.title')} icon="block">
          {translate('ttdNotifications.viewSelector.unauthorizedAccess.message')}
        </NoDataFiller>
      }
      noDataFiller={
        <NoDataFiller title={translate('ttdNotifications.viewSelector.noData.title')} icon="widgets" actionsTemplate={actionsTemplate}>
          {translate('ttdNotifications.viewSelector.noData.message')}
        </NoDataFiller>
      }>
        <List<NotificationModel> title={translate('ttdNotifications.title')}
          titleTemplate={<TitleTemplate />}
          items={itemsState}
          itemTitlePropertyPath="title"
          itemTextTemplate={<ItemTextTemplate />}
          itemIconPropertyPath={itemIconPropertyPath}
          itemIconClassesPropertyPath={itemIconClassesPropertyPath}
          itemIconTooltipPropertyPath={itemIconTooltipPropertyPath}
          itemHighlightPredicate={item => !!item.isUnread}
          actionsTemplate={
            <ActionTemplate helpClick={() => { helpClick(); analyticCallback(createPayloadHelpClick()); }} />}
          noDataFiller={
            <NoDataFiller title={translate('ttdNotifications.viewSelector.noData.title')} icon="widgets" actionsTemplate={actionsTemplate}>
              {translate('ttdNotifications.viewSelector.noData.message')}
            </NoDataFiller>
          }
          dataFetchingErrorFiller={
            <NoDataFiller title={translate('ttdNotifications.viewSelector.technicalIssue.title')} icon="error_outline" actionsTemplate={actionsTemplate}>
              {translate('ttdNotifications.viewSelector.technicalIssue.message')}
            </NoDataFiller>
          }
          onClick={ data => { itemClick(data); analyticCallback(createPayloadItemClick(data.item.title)); } }
          onTitleClick={() => { seeAllClick(); analyticCallback(createPayloadSeeAllClick()); }}
          pages={pages}
          onPageChange={(page) => pageChange(page)}
          isLoading={isLoading}
          hasDataFetchingError={hasDataFetchingErrorState}
        />
      </ViewSelector>
  
  const styles = getMergedStyles(listStyles, widgetStyles);
  return (
    <WithCss styles={styles}>
      <div className="wrapper d-flex bg-lvl1">
        { content }
      </div>
    </WithCss>
  );
}
widgetize("ttd-notifications", Notifications, {
  items: WidgetPropsMapping.asObject({
    description: "The items",
  }),
  hasUnauthorizedAccess: WidgetPropsMapping.asObject({
    description: "Indicates wether the access in authorized or not",
  }),
  hasDataFetchingError: WidgetPropsMapping.asObject({
    description: "Indicates if there was an error when trying to fetch the data",
  }),
  isLoading: WidgetPropsMapping.asObject({
    description: "Indicates if there is a load operation pending",
  }),
  pages: WidgetPropsMapping.asNumber({
    description: "Indicates the number of pages to display in the pager",
  }),
  pageChange: WidgetPropsMapping.asEventEmitter("page-change", {
    description: "Event occurring when a page is selected",
  }),
  itemClick: WidgetPropsMapping.asEventEmitter("item-click", {
    description: "Event occurring when an item is clicked with data containing the item payload",
  }),
  seeAllClick: WidgetPropsMapping.asEventEmitter("see-all-click", {
    description: "Event occurring when the see all button is clicked",
  }),
  helpClick: WidgetPropsMapping.asEventEmitter("help-click", {
    description: "Event occurring when the help button is clicked",
  }),
  analyticCallback: WidgetPropsMapping.asEventEmitter("analytic-callback", {
    description: "Event occurring when an event should be logged to the analytic module for monitoring",
  }),
  retryClick: WidgetPropsMapping.asEventEmitter("retry-click", {
    description: "Event occurring when retry button is clicked",
  })
});

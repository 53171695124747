import { WithT } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";


const TitleTemplate = ({ t }: any): JSX.Element => {
  return (
    <>
        <span>{t('ttdNotifications.title')}</span>
        <i className="icon icon-sm text-secondary ms-4px">arrow_forward_ios</i>
    </>);
}

  export default withTranslation()(TitleTemplate);
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { format, isToday } from 'date-fns';
import { fr } from "date-fns/locale";

const resources = {
  en: {
    translation: {
      common: {
        itemDate: "{{ date, itemDate }}"
      },
      ttdNotifications: {
        viewSelector: {
          noData: {
            title: 'No notifications',
            message: 'You do not have any notification.'
          },
          technicalIssue: {
            title: 'Technical issue',
            message: 'A problem occurred while loading this content.'
          },
          unauthorizedAccess: {
            title: 'Unauthorized access',
            message: 'You cannot access notifications with the "Navigate as" option.'
          }
        },
        title: 'Notifications',
        subtitle: '5 latest events for information',
        retryBtn: 'Retry',
        seeAllBtn: 'See all'
      }
    }
  },
  fr: {
    translation: {
      common: {
        itemDate: "{{ date, itemDate }}"
      },
      ttdNotifications: {
        viewSelector: {
          noData: {
            title: 'Pas de notifications',
            message: 'Vous n\'avez aucune notification.'
          },
          technicalIssue: {
            title: 'Problème technique',
            message: 'Un problème est survenu pendant le chargement de ce contenu.'
          },
          unauthorizedAccess: {
            title: 'Accès non autorisé',
            message: 'Vous ne pouvez pas accéder aux notifications avec l\'option "Navigate as".'
          }
        },
        title: 'Notifications',
        subtitle: '5 derniers évènements pour information',
        retryBtn: 'Réessayer',
        seeAllBtn: 'Voir tout',
      }
    }
  },
};

i18next
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    interpolation: {
      escapeValue: false,
      format: (value, fmt, lng) => {
        switch (fmt) {
          case 'itemDate':
            if (isToday(value)) {
              return lng === 'fr' 
                ? format(value, 'p', { locale: fr})
                : format(value, 'p');
            }
            return lng === 'fr'
              ? format(value, 'dd MMM yyyy', { locale: fr })
              : format(value, 'dd MMM yyyy')
          default:
            return value;
        }
      }
    },
  });

export default i18next;